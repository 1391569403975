// components
import Validation from '@/js/components/forms/validation';

class Register {
  constructor() {
    this.dom = {
      form: document.getElementById('register-form'),
    };

    this.components = {
      registerFormValidation: null,
    };

    this.mount();
  }

  mount() {
    if (this.dom.form) {
      this.components.registerFormValidation = new Validation(this.dom.form);
    }
  }
}
if (document.getElementById('page-register')) {
  // eslint-disable-next-line no-new
  new Register();
}
