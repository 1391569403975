import baguetteBox from 'baguettebox.js';

class Events {
  constructor() {
    this.dom = {
      pastEventCta: document.querySelectorAll('.past__events-cta'),
    };
    this.mount();
  }

  // eslint-disable-next-line class-methods-use-this
  mount() {
    baguetteBox.run('.past__events-slider', {
      animation: 'slideIn',
      noScrollbars: true,
    });

    if (this.dom.pastEventCta) {
      this.dom.pastEventCta.forEach((pastEventCta) => {
        pastEventCta.addEventListener('click', (event) => this.showGalleryAtIndex(event));
      });
    }

    // Initialize BaguetteBox for future events
    baguetteBox.run('.future__events-slider', {
      animation: 'slideIn',
      noScrollbars: true,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  showGalleryAtIndex(event) {
    const componentIndex = event.target.getAttribute('data-component-index');
    const adjustedIndex = componentIndex - 1; // Adjust for 0-based indexing
    const gallery = baguetteBox.run('.past__events-slider', {
      animation: 'slideIn',
      noScrollbars: true,
    });
    baguetteBox.show(
      0, // You may need to adjust this index based on your structure
      gallery[adjustedIndex], // Use the adjusted index
    );
  }
}

if (document.getElementById('page-event-detail')) {
  // eslint-disable-next-line no-new
  new Events();
}
