import Swiper, {
  Navigation, Pagination, EffectFade, Lazy, Autoplay,
} from 'swiper/core';

Swiper.use([Navigation, Pagination, EffectFade, Lazy, Autoplay]);

// eslint-disable-next-line no-unused-vars
const sliderWidget = new Swiper('.w_slider', {
  loop: true,
  slidesPerView: 1,
  slidesPerGroup: 1,
  spaceBetween: 80,
  autoplay: {
    delay: 3000,
  },
  speed: 500,
  breakpoints: {
    576: {
      slidesPerView: 2,
    },
    992: {
      slidesPerView: 3,
    },
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  // Disable preloading of all images
  preloadImages: false,
  // Enable lazy loading
  lazy: {
    checkInView: true,
    loadPrevNext: true,
  },
});

// console.log(sliderWidget);
