const spamCheck = {
  name: 'spamcheck',
  value: 'QzhZqSXD7KTgxhEJt5gWQlkbfUVPttQDW314',
};

export function attachSpamCheck(form) {
  if (form.querySelector('input[name="spamcheck"]')) return;

  const input = document.createElement('input');
  input.type = 'hidden';
  input.name = spamCheck.name;
  input.value = spamCheck.value;
  form.appendChild(input);
}

export default {
  attachSpamCheck,
};
