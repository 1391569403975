import { Modal } from 'bootstrap';
import Validation from '@/js/components/forms/validation';
import createCookie from '@/js/components/cookies/createCookie';

const newsletterPopup = document.getElementById('newsletter-modal');
const formSubscriber = document.getElementById('newsletter-form');
const disableOnThisPage = document.getElementById('newsletter-subscribe');

// Function to check if a cookie exists
function checkCookie(name) {
  const cookies = document.cookie.split(';');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(`${name}=`)) {
      return true;
    }
  }
  return false;
}

// Function to close the modal with a delay
function closeModalWithDelay(modal, delay) {
  setTimeout(() => {
    modal.hide();
  }, delay);
}

if (!disableOnThisPage) {
  if (newsletterPopup) {
    if (!checkCookie('modalClosed')) {
      const modal = new Modal(newsletterPopup, {
        backdrop: 'static',
      });

      newsletterPopup.addEventListener('hidden.bs.modal', () => {
        createCookie('modalClosed', 'true', 30);
      });

      modal.show();

      if (formSubscriber) {
        // eslint-disable-next-line no-unused-vars
        const subscriberFormValidation = new Validation(formSubscriber);

        document.addEventListener('formSuccess', () => {
          console.log('success');
          const delay = 2000;
          closeModalWithDelay(modal, delay);
        });
      }
    }
  }
}
