export default function viewRoom({ width }) {
  const rootStyles = getComputedStyle(document.documentElement);
  const scaleWidthValue = rootStyles.getPropertyValue('--scale-width').trim();
  const scale = parseInt(scaleWidthValue, 10);
  const scaleInCm = 200;
  const ratio = scale / scaleInCm;

  const scaledImgWidth = width * ratio;
  document.documentElement.style.setProperty('--image-width', `${scaledImgWidth}px`);
}
