import Swiper, {
  Navigation, Pagination, EffectFade, Lazy, Autoplay,
} from 'swiper/core';

import baguetteBox from 'baguettebox.js';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, EffectFade, Lazy, Autoplay]);

class ArtworkDetail {
  constructor() {
    this.dom = {
      artSlider: document.getElementById('art-slider'),
    };

    this.data = {
      artSliderConfig: {
        slidesPerView: 1,
        loop: this.dom.artSlider.querySelectorAll('.swiper-slide:not(.swiper-slide-duplicate)').length > 1,
        watchOverflow: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        preloadImages: false,
        lazy: {
          checkInView: true,
          loadPrevNext: true,
        },
      },
    };

    this.components = {
      artSliderInstance: null,
    };

    this.mount();
  }

  mount() {
    if (this.dom.artSlider) {
      this.components.artSliderInstance = new Swiper(this.dom.artSlider, this.data.artSliderConfig);
    }

    // Add a class to the 'a' tags within .swiper-slide-duplicate elements
    const duplicateSlides = document.querySelectorAll('.swiper-slide-duplicate');
    if (duplicateSlides) {
      duplicateSlides.forEach((slide) => {
        const duplicateLinks = slide.querySelectorAll('a');
        duplicateLinks.forEach((link) => {
          link.classList.add('lightbox-duplicate');
        });
      });
    }

    baguetteBox.run('#art-slider', {
      animation: 'slideIn',
      noScrollbars: true,
      ignoreClass: 'lightbox-duplicate',
    });
  }
}

if (document.getElementById('page-artwork-detail')) {
  // eslint-disable-next-line no-new
  new ArtworkDetail();
}
