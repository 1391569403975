export default class ReadMore {
  constructor(container) {
    this.container = container;
    this.link = container.querySelector('.read-more-link');
    this.text = container.querySelector('.show-more');

    if (this.link && this.text) {
      this.moreText = this.link.dataset.moreText;
      this.lessText = this.link.dataset.lessText || 'Show Less';
      this.link.addEventListener('click', this.toggleText.bind(this));
    }
  }

  toggleText(event) {
    event.preventDefault();
    const textDisplay = window.getComputedStyle(this.text).getPropertyValue('display');
    if (textDisplay === 'none') {
      this.text.style.display = 'inline';
      this.link.textContent = this.lessText;
    } else {
      this.text.style.display = 'none';
      this.link.textContent = this.moreText;
    }
  }
}
document.addEventListener('DOMContentLoaded', () => {
  const containers = document.querySelectorAll('.read-more');
  containers.forEach((container) => new ReadMore(container));
});
