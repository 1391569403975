import { addToFavorites, removeFromFavorites } from '@/js/api/artworks';
import { rootWithLanguage } from '@/js/utils/paths';

const favorites = document.querySelectorAll('.js-favorite-toggle');
let isLoading = false;
const favoriteClick = async (e) => {
  if (isLoading) {
    return;
  }

  const fav = e.currentTarget;
  // API call
  // Return state => active / inactive
  if (fav.dataset.loggedin === String(0)) {
    window.location.href = `${rootWithLanguage}login`;
    return;
  }

  isLoading = true;
  if (fav.classList.contains('active')) {
    await removeFromFavorites({ artworkId: fav.dataset.id })
      .then(() => {
        fav.classList.remove('active');
      })
      .catch(() => {
        console.log('error opvangen');
      });
    isLoading = false;
  } else {
    await addToFavorites({ artworkId: fav.dataset.id })
      .then(() => {
        fav.classList.add('active');
      })
      .catch(() => {
        console.log('error opvangen');
      });
    isLoading = false;
  }
};

favorites.forEach((favorite) => {
  favorite.addEventListener('click', (e) => favoriteClick(e));
});

console.log(favorites);
