import 'share-api-polyfill';

export default function share({ title = '', url = window.location.href }) {
  navigator.share(
    {
      title,
      url,
    },
    {
      copy: false,
    },
  )
    .then(() => {
      console.log(title, url);
    });
}
